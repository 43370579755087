@tailwind base;

body{
    overflow: hidden;
}

#toggle:checked ~ label div.toggle-dot{
    @apply translate-x-3
}

.tooltip {
    @apply invisible absolute;
    @apply bg-DarkBG;
    @apply dark:bg-DarkText;
    @apply text-lightText;
    @apply p-1;
    @apply z-50;
    @apply rounded-md;
}
  
.has-tooltip:hover .tooltip {
    @apply visible z-50
}

@tailwind components;
@tailwind utilities;

.item {
    @apply
    text-gray-100
    text-xl font-bold
    flex items-center content-center justify-center
    rounded
}

.navText{
    color: #1c274c;
    font-size: 16px;
    font-weight: 400;
}

.dark .navText{
    color: #E0E5F5;
}

.k-splitter{
    border: none;
}



