.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
/*.k-splitbar.k-splitbar-horizontal.k-splitbar-draggable-horizontal{*/
/*  background: #6d6d6d;*/
/*}*/
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.-scale-x-100 {
  --tw-scale-x: -1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

li>ul                 { transform: translatex(100%) scale(0) }
li:hover>ul           { transform: translatex(101%) scale(1) }
li > button svg       { transform: rotate(-90deg) }
li:hover > button svg { transform: rotate(-270deg) }

/* Below styles fake what can be achieved with the tailwind config
    you need to add the group-hover variant to scale and define your custom
    min width style.
    See https://codesandbox.io/s/tailwindcss-multilevel-dropdown-y91j7?file=/index.html
    for implementation with config file
*/
.group:hover .group-hover\:scale-100 { transform: scale(1) }
.group:hover .group-hover\:-rotate-180 { transform: rotate(180deg) }
.scale-0 { transform: scale(0) }
.min-w-32 { min-width: 8rem }

.k-splitbar:active, .k-splitbar.k-state-focus, .k-splitbar.k-state-focused{
  background: #6f6f6f;
}



.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
   animation: spinner 1.5s linear infinite;
 }

@-webkit-keyframes spinner {
  0 % { -webkit-transform: rotate(0deg);}
  100% {-webkit-transform: rotate(360deg);}
}

@keyframes spinner {
  0 % {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}


.ace_gutter-layer{
  background-color: white !important;
}

.dark .ace_gutter-layer{
  background-color: #272a2d !important;
}

.dark .ace_content{
  background-color: #272a2d !important;
}

.k-splitter-flex .k-splitbar{
  border-left: 1px solid #1c274c50 !important;
  border-right: 1px solid #1c274c50 !important;
  /* width: 30px !important; */
}
.dark .k-splitter-flex .k-splitbar{
  border-left: 1px solid #d6d6d630 !important;
  border-right: 1px solid #d6d6d630 !important;
  /* width: 30px !important; */
}

.ace_active-line {
  background-color: #f9fbfd !important;
}
.dark .ace_active-line{
  background-color: #18181820 !important;
}
.dark .ace_gutter-active-line{
  background-color: #18181820 !important;
}
.ace_gutter-active-line{
  background-color: #f9fbfd !important;
}
.k-splitter-overlay{
  background-color: green !important;
}

.basemodal{
  background-color: #00000050;
}

.blurScreen{
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: #2d2d2d50 !important;
}